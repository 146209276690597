:root {
  --color-primary: #4f91c6;
  --color-primary-lighten: #6ab0e8;
  --color-primary-darken: #1f5783;

  --color-success: #56a902;
  --color-error: #f15c5c;
  --color-warning: #f6a609;

  --color-white: #fff;
  --color-black: #0a0a0a;
  --color-black-secondary: #162937;
  --color-white-inactive: #f7f7fe;
  --color-grey: rgba(22, 41, 55, 0.32);
  --color-grey-light: #d8d8d8;

  --color-bg: #f9f9fb;

  --color-table-hover: #e3edf6;

  --icon-arrow: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}
