@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "../../static/styles/normalize.scss";
@import "_variables.scss";

body {
  background-color: var(--color-bg);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.tg_column {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.tg_page {
  font-family: "Roboto", sans-serif;
  color: var(--color-black);
  font-size: 16px;
  line-height: 24px;

  .tg_center {
    text-align: center;
  }

  h1 {
    font-size: 24px;
    color: var(--color-primary);
    line-height: 36px;
    font-weight: 500;

    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 29px;
    }
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;

    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding-left: 15px;

      &:before {
        content: "";
        background-image: var(--icon-arrow);
        display: inline-block;
        margin-left: -15px;
        width: 12px;
        height: 12px;
        transform: rotate(-90deg);
        opacity: 0.6;
        vertical-align: -1px;
        margin-right: 8px;
      }
    }
  }

  table,
  td,
  th {
    border: 1px solid var(--color-grey);
    text-align: left;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    background-color: var(--color-white);
  }

  th {
    color: var(--color-grey);
  }

  tr:hover {
    background-color: var(--color-table-hover);
  }

  th,
  td {
    padding: 12px;
    border-left: 0;
    border-right: 0;
  }

  a {
    color: var(--color-primary);

    &:hover {
      opacity: 0.68;
    }
  }
}
