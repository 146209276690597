.tg_button {
  background-color: var(--color-primary);
  border: none;
  outline: none;
  margin: 0;
  color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 24px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-lighten);
  }

  &:focus {
    outline: 2px solid var(--color-primary-darken);
  }

  &:active {
    border: none;
    background-color: var(--color-primary);
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:hover {
      background-color: var(--color-primary);
    }
  }

  &.tg_btn-large {
    padding: 20px 44px;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;

    &:after {
      content: "";
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid currentColor;
    }
  }
}
