.tg_radio {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;

  .tg_label {
    position: absolute;
    bottom: -30px;
    width: max-content;
  }

  .tg_icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;

    @media (max-width: 1024px) {
      width: 30px;
      height: 30px;
    }

    svg {
      display: none;
    }

    &:hover {
      background: var(--color-primary);
    }
  }

  & input[type="radio"] {
    display: none;

    &:checked + .tg_icon {
      background: var(--color-primary);

      svg {
        display: inline-flex;
        width: 20px;

        @media (max-width: 1024px) {
          width: 20px;
        }
      }
    }
  }
}
