.tg_test-info {
  .tg_button {
    display: block;
    margin: 0 auto;
  }

  .tg_column {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tg_test-welcome-html {
    width: 100%;
  }
}
