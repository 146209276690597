.tg_set-page {
  .tg_h2 {
    margin-bottom: 10px;
  }

  .tg_column {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .tg_button {
    display: block;
    margin: 0 auto;
  }

  .tg_form-item {
    margin-bottom: 40px;
    padding-bottom: 50px;
    border-bottom: 1px solid var(--color-grey-light);
    text-align: center;

    &.is-required {
      h1 {
        padding-left: 40px;
        padding-right: 5px;
      }

      h1::before,
      h1::after {
        color: var(--color-error);
        font-size: 34px;
        position: absolute;
      }

      h1::before {
        content: "*❯";
        margin-left: -40px;
      }

      h1::after {
        content: "❮";
        margin-left: 5px;
      }
    }

    @media (max-width: 1024px) {
      margin-bottom: 30px;
      padding-bottom: 40px;
    }
  }
}
