@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  hyphens: auto;
}

:root {
  --color-1: rgb(0, 96, 152);
  --color-2: rgb(0, 53, 101);
  --color-3: #ffffff;
  --color-bg: #eeeeee;
  --color-container: #fff;
}

.custom-reports {
  font-family: "PT Sans", sans-serif;
  background-color: #eeeeee;
  padding: 40px 0;

  .custom-reports-wrap {
    max-width: 900px;
    padding: 30px;
    margin: auto;
    background-color: #fff;
    border-radius: 10px;

    .report-header-wrap {
      margin-bottom: 90px;
      .icon img {
        padding: 50px;
        background-size: 100% 100%;
        background-image: url(./../../../../public/custom-reports/img/biwe/icon_analysis.svg);
      }
    }

    .accardion-wrap {
      margin-bottom: 30px;
    }

    .text-end img {
      padding: 32px 110px;
      background-size: 100% 100%;
      background-image: url(./../../../../public/custom-reports/img/biwe/logo.svg);
    }
  }

  .list-1 li .row img.traffic-light-img-0,
  .list-1 li .row img.traffic-light-img-1,
  .list-1 li .row img.traffic-light-img-2 {
    padding: 45px;
    background-size: 100% 100%;
  }

  .list-1 li .row img.traffic-light-img-0 {
    background-image: url(./../../../../public/custom-reports/img/biwe/section_1.png);
  }

  .list-1 li .row img.traffic-light-img-1 {
    background-image: url(./../../../../public/custom-reports/img/biwe/section_2.png);
  }

  .list-1 li .row img.traffic-light-img-2 {
    background-image: url(./../../../../public/custom-reports/img/biwe/section_3.png);
  }

  .chart {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .chart-item {
    border-radius: 4px;
    border: 2px solid var(--color-1);
    padding-bottom: 20px;
  }

  .chart-item-title {
    font-size: 0.7em;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    position: relative;
    background-color: white;
    left: 14px;
    top: -14px;
    padding: 5px;
    color: var(--color-1);
  }

  .details-button {
    cursor: pointer;
    font-size: 17px;
    font-weight: normal;
    background-color: var(--color-1);
    padding: 3px 14px;
    color: white;
    border: no-border;
    text-decoration: none;
    margin: 0 auto;
    display: table;
    position: relative;
    top: -45px;

    @media (max-width: 767px) {
      top: inherit;
      margin: 20px auto;
    }
  }

  .tabs {
    background: #eee;
  }

  .tab-wrap {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 20px;
  }

  .traffic-light-number {
    font-size: 1.5em;
  }

  .accordion-item {
    margin-top: 10px;

    & .accordion-header .accordion-button img.icon-action {
      padding: 25px 30px;
      background-size: 100% 100%;
      background-image: url(./../../../../public/custom-reports/img/biwe/icon_action.svg);
    }

    & .accordion-header .accordion-button img.icon-list {
      padding: 30px;
      background-size: 100% 100%;
      background-image: url(./../../../../public/custom-reports/img/biwe/icon_list.svg);
    }
  }

  .accordion-header .accordion-button {
    border: 2px solid var(--color-1);
    border-radius: 5px;
    margin-top: 10px;
    background-color: #eee;
    font-size: 0.6em;
  }

  .tab-head {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
    padding: 5px;
    border-radius: 5px;
    flex-grow: 1;
    color: #222;
    background: none;
    font-family: "Oswald";
    border: 2px solid #666;

    &.active {
      color: var(--color-1);
      background-color: #fff;
    }
  }
  .bars-container {
    display: flex;
    justify-content: center;
    gap: 15px;
  }

  .bar {
    width: 45px;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 5px;
  }

  .bar-inner {
    border-radius: 5px;
  }
  .bar-cap {
    font-size: 19px;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    color: var(--color-1);
    text-align: center;
    margin-top: 5px;
  }
  .row {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
  }

  .row > * {
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    margin-top: var(--bs-gutter-y);
  }

  body {
    background-color: var(--color-bg);
    padding-bottom: 40px;
    border-bottom: 40px solid var(--color-1);
  }

  .container {
    background-color: var(--color-container);
    max-width: 900px;
    padding: 30px;
    border-radius: 10px;
    margin-top: 20px;
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--color-1);
    font-family: "Oswald", sans-serif;
    font-weight: 600;
  }

  p {
    font-family: "PT Sans", sans-serif !important;
    font-style: normal;
    font-weight: 400;
  }

  p.thankyou {
    font-family: "Oswald", sans-serif !important;
    font-size: 1.3em;
    color: var(--color-1);
  }

  p.farewell {
    font-family: "Oswald", sans-serif !important;
    font-size: 1.1em;
    padding-bottom: 50px;
  }

  b,
  strong {
    font-weight: 600;
  }

  .highlight-number {
    color: var(--color-1);
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    font-size: 1.5em;
    background-color: lightgrey;
    padding: 8px;
    border-radius: 4px;
  }

  p.name {
    color: var(--color-1);
    font-family: "PT Sans", sans-serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 5px;
  }

  .icon {
    width: 100px;
    height: 70px;
    margin-top: -20px;
    float: right;
    display: block;
  }

  .traffic-light-text {
    padding-left: 10px;
  }

  @media (max-width: 550px) {
    .traffic-light {
      height: 50px;
    }

    .traffic-light-container img {
      // margin-right: 2px;
      margin-bottom: 2px;
    }

    .traffic-light-container {
      padding-left: 8px;
      display: block;
    }

    .dummy-bars {
      width: 100%;
      max-width: 150px;
    }

    .nav-pills .nav-link {
      font-size: 0.8em;
    }
  }

  @media (min-width: 551px) {
    .traffic-light {
      /* height: 60px; */
      height: 90px;

      min-width: 77px;
    }

    .traffic-light-container img {
      margin-right: -20px;
      margin-bottom: 5px;
    }

    .traffic-light-container {
      padding: 10px;
      display: block;
    }

    .dummy-bars {
      max-height: 220px;
    }
  }

  .accordion-button {
    border: 2px solid var(--color-1);
    border-radius: 5px;
    // margin-top: 10px;
    background-color: #eee;
    font-size: 0.6em;
  }

  .accordion-button:not(.collapsed) {
    background-color: var(--color-1);
    color: white;
  }

  .accordion-body {
    background: #eee;
    margin-top: 10px;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background: white;
    border-radius: 3px;
    font-family: "Oswald";
    border: 2px solid var(--color-1);
    color: var(--color-1);
  }

  .nav-pills .nav-link {
    color: #222;
    background: none;
    font-family: "Oswald";
    border: 2px solid #666;
  }

  .nav-pills .nav-link button {
    color: #222;
    font-family: "Oswald";
  }

  ul.list-1,
  ul.list-2,
  ul.list-3 {
    font-family: "Oswald";
    color: var(--color-1);
    text-align: left;
    margin-top: 20px;
    list-style: none;
    margin-left: 0px;
    padding-left: 0;
    font-size: 1.1em;
  }

  ul.list-2 {
    font-family: "Oswald";
    font-size: 1.1em;
  }

  ul.list-2 li {
    border-top: 1px solid var(--color-1);
    padding: 5px 0px 5px 10px;
  }

  ul.list-1 li {
    border-top: 1px solid var(--color-1);
    padding: 5px 0px 5px 10px;
  }

  ul.list-3 li {
    border-top: 0px solid var(--color-1);
    padding: 5px 0px 5px 10px;
  }

  .logo-1 {
    width: 100%;
  }

  .logo-1 img {
    margin-top: 20px;
    max-width: 220px;
    float: right;
    display: block;
  }

  .custom-grid {
    display: flex;
    row-gap: 50px;
    transition: all 1s ease-in-out;
  }

  .custom-grid-data-0 > * {
    flex: 1;
  }

  .custom-grid:hover {
    grid-template-rows: 50px;
  }

  .custom-grid-data-0 {
    color: white;
    font-size: 15px;
    text-align: center;
  }

  .legende {
    padding-left: 5px;
  }

  .opacity-1 {
    outline: 3px dotted #fff !important;
    outline-offset: -3px;
    background: none !important;
  }

  .percentage {
    color: #fff !important;
    font-size: 20px;
    text-align: left;
    display: block;
    opacity: 1;
    font-family: "Oswald";
    transition: all 1s ease-in-out;
    margin-left: 5px;
  }

  .custom-grid:hover .percentage {
    opacity: 1;
    font-size: 30px;
  }

  .tg_attribute_filters {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 40px;

    @media (max-width: 550px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  /* .custom-grid-1:hover {
  display: grid;
  grid-template-columns: 6% 12% 24% 28% 10% 20%;
  grid-template-rows: 50px;
} */

  @media (max-width: 550px) {
    .percentage {
      font-size: 14px;
    }

    .custom-grid:hover .percentage {
      opacity: 1;
      font-size: 14px;
    }

    ul.list-2 {
      font-family: "Oswald";
      font-size: 1em;
    }
  }
  .column-1 {
    background: var(--color-1);
    opacity: 0.3;
  }

  .column-2 {
    background: var(--color-1);
    opacity: 0.5;
  }

  .column-3 {
    background: var(--color-1);
    opacity: 0.7;
  }

  .column-4 {
    background: var(--color-1);
    opacity: 0.9;
  }

  .column-5 {
    background: var(--color-2);
    opacity: 0.8;
  }

  .column-6 {
    background: var(--color-2);
    opacity: 1;
  }

  .tg_attribute_filters {
    margin-top: -50px;
  }
}
