@import "static/styles/variables.scss";

html,
body,
#root {
  height: 100%;
}

:root {
  --base-text-color: #00171f;
  --base-text-font-type: "Roboto", sans-serif;
  --secondary-text-color: #cccccc;
  --headline-color: #00171f;
  --headline-text-font-type: "Lato", sans-serif;
  --button-background-rgb: 0, 23, 31;
  --button-color: #ffffff;
  --form-input-color: #00171f;
  --form-input-disabled-color: #cccccc;
  --link-color: #03468b;
  --tooltip-color: #03468b;
  --loader-color: #008962;

  --radar-top-color: #00bc87;
  --radar-middle-color: #00000033;
  --radar-bottom-color: #ee0000;
  --radar-labels-color: #00000080;

  --radar-tag-text-color: var(--base-text-color);
  --white-rgb: 255, 255, 255;
  --white: rgb(var(--white-rgb));
  --grey: rgb(208, 208, 208);
  --box-shadow: -10px 0px 10px 1px #00000055;
}
