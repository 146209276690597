.tg_gauge-wrapper {
  width: 256px;
}

.tg_visualization-wrapper {
  margin: 0 auto;
  text-align: center;
  svg {
    max-width: 430px;
    max-height: 200px;
  }
}

.tg_category {
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  padding: 10px 50px;
  border: 1px solid var(--color-grey);
  border-top: 0 none;
  margin: 0 auto;
  position: relative;
  &:first-child {
    border-top: 1px solid var(--color-grey);
  }

  .tg_check-mark {
    left: 20px;
    top: calc(50% - 15px);
    position: absolute;
  }
}
.tg_categories {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.tg_check-mark {
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--color-success);
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
  }
}
