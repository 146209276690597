.tg_radio-group-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 40px 0 0;
  grid-template-areas: "title1 radio title2";

  .tg_group-title {
    margin: 0;
    hyphens: auto;
    font-size: 20px;
    font-weight: 500;

    &:first-child {
      grid-area: title1;
      text-align: left;
    }
    &:last-child {
      grid-area: title2;
      text-align: right;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      font-weight: 400;
    }
  }

  @media (max-width: 1024px) {
    justify-content: space-between;
    padding: 0;
    grid-template-columns: repeat(2, 33%);
    grid-template-areas:
      "title1 title2"
      "radio radio";
  }

  .tg_radio-group {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: radio;

    @media (max-width: 1024px) {
      justify-content: space-between;
    }

    .tg_radio {
      margin: 0 20px;

      @media (max-width: 1024px) {
        margin: 0;

        &:last-child .tg_label {
          right: 0;
        }
        &:first-child .tg_label {
          left: 0;
        }
      }
    }

    & .tg_radio .tg_label {
      display: none;
    }

    & .tg_radio input:checked ~ .tg_label {
      display: block;
    }

    &:hover .tg_radio input:checked ~ .tg_label,
    &:hover .tg_radio .tg_label {
      display: none;
    }

    &:hover .tg_radio:hover input:checked ~ .tg_label,
    &:hover .tg_radio:hover .tg_label {
      display: block;
    }
  }
}

.tg_multi-select-wrapper {
  max-width: 400px;
  margin: 0 auto;
}

textarea.tg_text-field {
  width: 100%;
  max-width: 600px;
}

.tg_test-sub-heading-html {
  text-align: left;
}
