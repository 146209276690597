.tg_test-results-page {
  .header {
    padding-bottom: 20px;
  }

  .footer {
    padding-top: 20px;
  }
}

.tg_test-result {
  border-bottom: 1px solid var(--color-grey-light);
  padding-bottom: 20px;
  &:last-child {
    border-bottom: 0 none;
  }
}

.tg_set-result {
  padding: 40px 0;
  border-bottom: 3px solid var(--color-grey-light);
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: 0 none;
  }
}
