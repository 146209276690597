.aggregated-dashboard-page {
  .tg_column {
    max-width: none;
  }
  .word-cloud-canvas-container {
    canvas {
    }
  }

  .replies-count {
    border-bottom: 1px solid var(--color-grey-light);
    margin-bottom: 40px;
  }

  .scale-container {
    border-bottom: 1px solid var(--color-grey-light);
    padding-bottom: 20px;
  }

  .visualization-wrapper {
    .visualization-items {
      display: flex;
      flex-wrap: wrap;

      .visualization-item {
        text-align: center;
        margin-right: 50px;

        .tg_visualization-wrapper {
          margin: 0;
        }
      }
    }
  }

  .statistic-container {
    max-width: 600px;
  }

  .tg_attribute_filters {
    max-width: 600px;

    .tg_attribute_filter {
      margin-bottom: 20px;

      .tg_attribute_name {
        font-size: 24px;
        color: var(--color-primary);
        line-height: 36px;
        font-weight: 500;
      }
    }
  }
}
