.test-special-attributes-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .tg_attribute {
    padding-bottom: 20px;
    text-align: center;
  }
}
