.tg_text-field {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey);
  outline: none;
  border-radius: 0;
  padding: 9px 13px;
  font-family: inherit;
  font-size: inherit;

  &:focus {
    border-color: var(--color-primary);
  }

  & .tg_error {
    border-color: var(--color-error);
  }
}
