.tool-main-container,
.tool-page {
  height: 100%;
}

.tool-page {
  .links {
    a {
      margin-right: 20px;
    }
  }
}

.list-page {
  height: calc(100% - 48px);
  padding-top: 20px;

  .table-container {
    margin-top: 20px;
    max-height: calc(100% - 70px);
  }
}
