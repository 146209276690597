.tg_select {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color-black);
  background-color: var(--color-white);
  background-image: var(--icon-arrow);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--color-grey);
  border-radius: 0;
  appearance: none;
  outline: none;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus {
    border-color: var(--color-primary);
  }
}
